export const menuItems = [
  {
    name: "about",
    label: "About",
    name: "about"
  },
  {
    name: "services",
    label: "Services",
    name: "services"
  },
  {
    name: "Blog",
    label: "Blog",
    name: "blog"
  }
];
